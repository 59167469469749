<template>
  <div class="email-account-edit-page">
    <vuestic-widget :loading="isLoadingIntegration">
      <template slot="header">
        <span v-if="integration" class="title">{{ integration.title }} Setup</span>
      </template>
      <div v-if="integration" class="smtp-provider-wrapper">
        <ValidationObserver v-slot="{ handleSubmit, invalid, dirty }" ref="smptForm">
          <form @submit.prevent="handleSubmit(save)">
            <div class="row">
              <div class="col-md-12 mb-3 d-flex align-items-center justify-content-start">
                <img class="integraion-logo" height="70px" :src="integration.logo">
                <div class="pl-3">
                  <h5 class="text-primary font-weight-bold">{{ integration.title }}</h5>
                  <p class="my-0">{{ integration.description }}</p>
                </div>
              </div>
              <div class="col-md-6">
                <text-input label="Name" v-model="data.name" name="name" validate="required"></text-input>
              </div>
              <div class="col-md-6">
              </div>
              <div class="col-md-6">
                <text-input label="Api Key" v-model="data.api_key" name="api_key" validate="required"></text-input>
              </div>
              <div class="col-md-6">
              </div>
              <div class="col-md-6">
                <text-input type="email" name="from_name" v-model="data.options.from_name" label="Sender Name"
                  tooltip="Emails will be sent out from this sender name. Leave empty and default business name will be used." />
              </div>
              <div class="col-md-6">
                <text-input type="email" name="from_email" v-model="data.options.from_email" label="From Email"
                  validate="required|email"
                  tooltip="Emails will be sent out from this from email address." />
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-12 mb-2">
                <h5 class="text-primary font-weight-bold">Signature</h5>
              </div>
              <div class="col-md-12">
                <TextEditorPicker
                  :show-bottom="false"
                  v-model="data.options.signature"
                  :height="200"
                  ref="texteditor"
                />
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-12 mb-4">
                <h5 class="text-primary font-weight-bold">Opt-out Text</h5>
                <p>Specify your opt-out text at the bottom of the email. Make it sound friendly and personal.</p>
              </div>
              <div class="col-md-12">
                <text-input label="Opt-out Text" v-model="data.options.optout_text" name="optout_text"></text-input>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12 text-center">
                <router-link class="btn btn-danger mr-2" :to="{ name: 'business.setting', query: { tab: 2 } }">
                  <span>Back</span>
                </router-link>
                <button class="btn btn-primary" :disabled="invalid || processing">
                  <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                    style="margin: auto" />
                  <span v-else>Create</span>
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </vuestic-widget>
  </div>
</template>
  
<script>
import TextEditorPicker from "../../../common/TextEditorPicker";
export default {
  components: {
    TextEditorPicker,
  },
  data() {
    return {
      isLoadingIntegration: false,
      integration: null,
      processing: false,
      data: {
        api_key: '',
        name: '',
        options: {
        },
      }
    };
  },
  mounted() {
    this.getIntegrationType()
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    getIntegrationType() {
      this.isLoadingIntegration = true
      const typeId = this.$route.query.id

      this.$store
        .dispatch('businessIntegration/type', typeId)
        .then((integration) => {
          this.integration = integration
          this.isLoadingIntegration = false
        })
        .catch(() => {
          this.isLoadingIntegration = false
          this.$router.push({ name: 'business.setting', query: { tab: 2 } })
        })
    },

    save() {
      this.processing = true

      const param = {
        ...this.data,
        integrationId: this.integration.id,
      }

      this.$store
        .dispatch("businessIntegration/create", param)
        .then(() => {
          this.processing = false
          this.$store.dispatch('auth/getProfile')
          this.$router.push({ name: 'business.setting', query: { tab: 2 } })
        })
        .catch((err) => {
          this.processing = false
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.smtp-provider-wrapper {
  max-width: 1024px;
}
</style>
  
<style lang="scss">
.smtp-provider-wrapper {
  div.control-label {
    font-size: 0.6rem;
    color: #333;
    font-weight: 600;
    text-transform: uppercase;
  }

  .widget-body {
    padding: 0;
  }
}
</style>